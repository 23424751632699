import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { API, graphqlOperation } from "aws-amplify";

import { useProjects } from "contexts/projects";
import { useDebounce } from "hooks/debounce";
import { getContact } from "graphql/queries";
import { Contact } from "API";
import { Project as ProjectType } from "API";

interface ProjectProps {
  projects: ProjectType[];
}

export default function Project({ projects }: ProjectProps) {
  const { control, setValue, watch } = useFormContext();
  const { refetch, loading } = useProjects();
  const [inputText, setInputText] = useState<string>("");
  const debouncedInputText = useDebounce(inputText, 500);

  const [selectedProject, setSelectedProject] = useState<{
    name: string | undefined;
    id: string | undefined;
  } | null>(null);
  const [options, setOptions] = useState<any[]>([]);

  const pastProjectId = watch("pastProjectId");

  useEffect(() => {
    setOptions(
      projects.map((acc, idx) => ({
        name: acc.name,
        reference: acc.reference,
        id: acc.id,
        key: acc.id + idx,
      }))
    );
  }, [projects]);

  useEffect(() => {
    if (pastProjectId) {
      const project = projects.find((p) => p.id === pastProjectId);
      if (project) {
        if (project.account?.active ?? true) {
          setValue("accountId", project.accountId);
          setValue("accountName", project.accountName);
        }
        // useEffectの型エラー回避のためawaitでなくthen使用
        getContactById(project.contactId).then((contact) => {
          if (!contact) {
            setValue("contactId", null);
            setValue("contactName", null);
          } else if (contact.active ?? true) {
            setValue("contactId", project.contactId);
            setValue("contactName", project.contactName);
          }
        });
        setValue("projectId", project.id);
        setValue("type", project.type);
        setSelectedProject({ name: project.name, id: project.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastProjectId]);

  const reload = async (keyword: string) => {
    await refetch({
      filter: {
        name: {
          contains: keyword,
        },
      },
      limit: 9999,
    });
  };

  useEffect(() => {
    if (debouncedInputText) {
      reload(debouncedInputText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputText]);

  const getContactById = async (id: string): Promise<Contact | null> => {
    try {
      const contact = (await API.graphql(graphqlOperation(getContact, { id })))
        .data.getContact;
      return contact;
    } catch (err) {
      return null;
    }
  };

  return (
    <Autocomplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      getOptionLabel={(opt) => opt.name}
      options={options}
      renderOption={(props, option) => (
        <Box key={option.key} component="li" {...props}>
          {option.name}
        </Box>
      )}
      value={selectedProject || null}
      onInputChange={(_, value) => {
        setInputText(value || "");
      }}
      onChange={async (_, value) => {
        //TODO: cannot set value to the form
        if (value) {
          const project = projects.find((p) => p.id === value.id);
          if (project) {
            if (project?.account?.active ?? true) {
              setValue("accountId", project.accountId);
              setValue("accountName", project.accountName);
            }
            const contact = await getContactById(project.contactId);
            if (!contact) {
              setValue("contactId", null);
              setValue("contactName", null);
            } else if (contact.active ?? true) {
              setValue("contactId", project.contactId);
              setValue("contactName", project.contactName);
            }
          }
          setValue("projectId", project?.id);
          setValue("type", project?.type);
          setSelectedProject({ name: project?.name, id: project?.id });
        } else {
          setValue("accountId", null);
          setValue("accountName", null);
          setValue("contactId", null);
          setValue("contactName", null);
          setValue("projectId", null);
          setValue("type", null);
          setSelectedProject(null);
        }
      }}
      loading={loading}
      renderInput={(params) => (
        <Controller
          render={({ field }) => (
            <TextField
              sx={{ width: "100%" }}
              label="過去案件"
              variant="outlined"
              {...field}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          control={control}
          name="pastProjectId"
        />
      )}
    />
  );
}
